<template>
  <VCard>
    <VCardTitle>Создание конфига</VCardTitle>
    <VCardText>
      <VAutocomplete
        v-model.trim="companyId"
        :items="companies"
        :filter="customFilter"
        item-text="name"
        item-value="id"
        label="Компания"
      >
        <template #selection="{ item }">
          <VListItemContent>
            <VListItemTitle v-text="item.name" />
            <VListItemSubtitle v-text="item.id" />
          </VListItemContent>
        </template>

        <template #item="{ item }">
          <VListItemContent>
            <VListItemTitle v-text="item.name" />
            <VListItemSubtitle v-text="item.id" />
          </VListItemContent>
        </template>
      </VAutocomplete>

      <VBtn
        depressed
        color="primary"
        @click="createSmsConfig"
      >
        Создать
      </VBtn>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'SmsConfigCreate',

  props: {
    companies: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      companyId: '',
    };
  },
  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    createSmsConfig() {
      /**
       * Note: для создания требуется запросить через smsConfigGet - если не найдёт id, тогда создаст дефолтный
       * */

      this.$di.api.Talent
        .smsConfigGet({ companyId: this.companyId })
        .then(() => {
          this.$emit('created:config');
          this.companyId = '';
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
