<template>
  <TTView>
    <VRow>
      <VCol>
        <SmsConfigCreate
          :companies="companies"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import SmsConfigCreate from '../../../components/sms/SmsConfigCreate.vue';

export default {
  name: 'SmsCreate',

  components: { SmsConfigCreate },

  inject: ['Names'],

  data() {
    return {
      companies: [],
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const companiesResponse = await this.$di.api.Account.indexCompany();

        this.companies = companiesResponse.companies;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
